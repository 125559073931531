/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
}

html,
body,
div,
img {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: Helvetica, sans-serif;
  font-size: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100vh;
  width: 100vw;
  max-height: -webkit-fill-available;
  background: #000000;
  line-height: 1;
}

.no-scroll {
  overflow: hidden;
}

.loading-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  font-size: 19px;
  color: #ffffff;

  animation-name: spin-keyframes;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin-keyframes {
    from {
      transform: rotate(0deg);
    } to {
      transform: rotate(360deg);
    }
  }
}
